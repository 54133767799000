import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import {ShopInfoProps, withShopInfo} from "../contexts/ShopInfoContext";
import Icons from "../components/Icons";
type NotFoundProps = WithTranslation & ShopInfoProps;
class NotFound  extends React.Component<NotFoundProps, any> {
  public constructor(props: any) {
    super(props);
    this.state = {};
  }

  public static async getInitialProps(): Promise<any> {
    return {statusCode: 404};
  }

  public render() {
    return (
      <div className={'page404 container-fluid container-fluid-dt my-5 px-4 flex-column'}>
        <div className={"d-flex lightgrey align-items-center"}>
        <Icons icon="sad" size={100} className={'lightgrey mb3'}/>
          <h1 className={"font40 ml-3"}>{this.props.t('oops')}</h1>
        </div>
        <p className={"mt-4 text-center font20 sentenceContainer404"}>
          {this.props.t('404_sentence')}
          <span className={'d-block text-center pt-1 font14'}>{this.props.t('error_code')}</span>
        </p>
        <a href={'/'} className={'font20 btn-dt-primary mt-5 btn btn-secondary'}>  {this.props.t('back_to_home')}</a>
      </div>
    );
  }
  
}
export default withTranslation('404')(withShopInfo(NotFound));